<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <students-deleted-datatable/>
      </div>
    </div>
  </div>
</template>

<script>

  import StudentsDeletedDatatable from "@/pages/Students/datatables/StudentsDeletedDatatable";
  export default {
    name: "IndexWaitingStudentsPage",
    components: {StudentsDeletedDatatable}
  }
</script>

<style scoped lang="scss">

</style>
