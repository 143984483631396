<template>
  <octo-table
    show-filters
    :show-filters-collapse="false"
    show-query-input
    :action="endpoints.DATATABLES.studentsDeleted"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="filters = {}"
  >
    <template v-slot:student="data">
          {{ data.row.registry | optional('surname') }} {{ data.row.registry | optional('name') }}
    </template>

    <template v-slot:course="data">
      <div class="small text-truncate">
        {{courses[data.row.course_id] | optional('code')}} - {{courses[data.row.course_id] | optional('title')}}
      </div>

    </template>


    <template v-slot:actions="data">
          <base-button
            class="mx-1"
            @click="restoreStudent(data.row)"
            size="sm"
            confirm-required
            link
            icon
          >
            <octo-icon icon="restore"/>
          </base-button>
    </template>

    <template slot="custom-filters">
    </template>
  </octo-table>
</template>

<script>
import {mapGetters} from "vuex";
import {Select, Option} from "element-ui";
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import StudentStatuses from "@/pages/Students/resources/studentStatuses";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import classroomTypes from "@/pages/Didactics/resources/classroomTypes";
import {permissionService} from "@/util/permission-service";
import DealWeeklyFrequencies from "@/pages/Deals/resources/dealWeeklyFrequencies";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import {schedulerService} from "@/util/scheduler-service";

  export default {
    name: "StudentsDeletedDatatable",
    components: {
      LabelThemeComponent,
      Badge,
      OctoIcon,
      OctoTable,
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        name: 'StudentsDeletedDatatable',
        endpoints: endpoints,
        filters: {},
        fields: [
          {prop: 'student', label: 'student', minWidth: 180, slot: true, align: 'left'},
          {prop: 'course', label: 'course',  minWidth: 300, slot: true, align: 'left'},
          {prop: 'actions', label: 'actions', width: 100, slot: true, align: 'center', fixed: 'right'},
        ],
      }
    },
    computed: {
      ...mapGetters({
        getFilters: 'common/datatableFilters',
        courses: 'common/allCourses',
      }),

    },
    beforeMount() {
      this.filters = this.getFilters(this.name);
    },
    methods: {
      restoreStudent(student) {
        this.$fullLoading.show();

        this.$api.put(endpoints.STUDENT_RESTORE.replace('{id}', student.id))
          .then(() => {
            this.$notify({type: 'success', message: this.$t('notifications.restored_success')});
            this.$router.push({name: 'students.waiting.show', params: {id: student.id}});
          })
          .catch(() => {
            this.$notify({type: 'danger', message: this.$t('notifications.restored_error')});
          })
          .finally(() => {
            this.$fullLoading.hide();
          })
      }
    }
  }
</script>

<style scoped>

</style>
